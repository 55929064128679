import logo from './cloveonly logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div className="App-logo1"> </div>
        <div className="App-logo"> </div>
        <img src={logo} className='logo' alt="logo" />
        
      </header>
      <p>
          Spisook Agritech pvt.ltd
        </p>
        
    </div>
  );
}

export default App;
